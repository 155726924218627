import React from 'react';
import './App.css';
import { CardDraw } from './components/CardDraw';

function App() {
  return (
    <CardDraw />
  );
}

export default App;
