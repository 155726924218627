export const suits = ['♣','♠','♥','♦']
export const ranks = ['A', '2', '3', '4', '5', '6', '7', '8', '9', '10', 'J', 'Q', 'K']

export class Hand {
    hand: Card[];
    constructor(obj?: Hand) {
        this.hand = obj?.hand || [];
    }
    
    set pickCard(Card:Card) {
        this.pickCard = this.hand[this.hand.indexOf(Card)]
    }

    get size() {
        return this.hand.length;
    }

    get randomCard() {
        return this.hand[Math.floor(Math.random() * this.hand.length)];
    }
}

export class Deck {
    deck: Card[]
    constructor(obj?: Deck) {
        this.deck = obj?.deck || [];
    }

    public get length() {
        return this.deck.length
    }

    public newDeck() {
        this.deck = suits.flatMap(suit => ranks.map(rank => new Card({suit, rank})))
    }

    public shuffleDeck() {
        let count = this.length;
        while(count > 0) {
            this.deck.push(this.deck.splice(Math.floor(Math.random() * count), 1)[0])
            count -= 1
        }
    }

    public draw() {
        return this.deck.pop()
    }
}

export class Card {
    suit: string;
    rank: string
    constructor(obj?: Card) {
        this.suit = obj?.suit || '';
        this.rank = obj?.rank || '';
    }
}