import cards from './cards.png';
import React, { useEffect, useState } from 'react';
import { Card, ranks, suits } from './Deck';

export const CardImage: React.FC<{ card: Card }> = ({ card }) => {
    const [top, setTop] = useState(0);
    const [left, setLeft] = useState(0);

    useEffect(() => {
        card && setTop((392 / 4) * (suits.indexOf(card.suit)));
        card && setLeft((950 / 13) * (ranks.indexOf(card.rank)));
    }, [card])

    return <div style={{
        backgroundRepeat: 'no-repeat',
        height: `${(392 / 4)}px`,
        width: `${(950 / 13)}px`,
        backgroundImage: `url(${cards})`,
        backgroundPosition: '-' + left + 'px ' + '-' + top + 'px'
    }}></div>
}
