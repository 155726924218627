import React, { useEffect, useState } from 'react';
import { CardImage } from '../Deck/Cards';
import { Deck } from '../Deck/Deck';
import image from '../Deck/cards.png'

export const CardDraw: React.FC = () => {
    const [deck, setDeck] = useState(new Deck().deck);
    const [randomCard, setRandomCard] = useState<number>(Math.floor(Math.random() * deck.length));
    useEffect(() => newDeck(), [])

    function newDeck() {
        let pile = new Deck();
        pile.newDeck();
        setDeck(pile.deck);
    }

    function shuffle() {
        let pile = new Deck();
        pile.newDeck();
        pile.shuffleDeck();
        setDeck(pile.deck);
    }

    function pickCard() {
        setRandomCard(Math.floor(Math.random() * deck.length))
    }

    return (
        <>
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                {deck.length > 0 && <CardImage card={deck[randomCard]} />}
            </div>
            <hr />
            <div style={{ display: 'flex', justifyContent: 'center' }}>
                <button onClick={shuffle}>Shuffle Deck</button>
                <button onClick={pickCard}>Pick Card</button>
            </div>
        </>)
}